import aetna from "@assets/common/InsurancePlans/aetna.webp"
import anthem from "@assets/common/InsurancePlans/anthem.webp"
import blueCrossBlueShield from "@assets/common/InsurancePlans/blue-cross-blue-shield.webp"
import cigna from "@assets/common/InsurancePlans/cigna.webp"
import healthNet from "@assets/common/InsurancePlans/health-net.webp"
import meritainHealth from "@assets/common/InsurancePlans/meritain-health.webp"
import outOfPocket from "@assets/home/out-of-pocket.webp"
import umr from "@assets/common/InsurancePlans/umr.webp"
import unitedHealthcare from "@assets/common/InsurancePlans/united-healthcare.webp"

export default [
  { name: "Blue Cross/Blue Shield", image: blueCrossBlueShield },
  { name: "Health Net", image: healthNet },
  { name: "United Healthcare", image: unitedHealthcare },
  { name: "Anthem", image: anthem },
  { name: "Aetna", image: aetna },
  { name: "Cigna Insurance", image: cigna },
  { name: "UMR", image: umr },
  { name: "Meritain Health", image: meritainHealth },
]

export const OUT_OF_POCKET = {
  name: "Out of Pocket",
  image: outOfPocket,
}
