const productIds = {
  "/blog-post/do-primary-care-doctors-do-blood-work":
    "32dc3727-8981-416b-9b2b-e8bc0098779c",
  "/covid": "4faaa9ca-f8d8-4106-bbc6-408419b02ee5",
  "/mobile-aoc/centralize-care": "de491cb3-9d55-450f-a875-cb7afb21b285",
  "/mobile-aoc/hair-loss": "fc578f7e-7d56-4e6f-a938-dcd52d93ec5a",
  "/mobile-aoc/lgbtq-health": "c75ddf4c-fa3f-4799-9e5b-83b5d6814702",
  "/mobile-aoc/mental-health": "5c2673a1-3b2f-4ee9-95b6-d876a1a8ed7e",
  "/mobile-aoc/sleep-better": "1e314f52-9473-4aef-9b4e-295df52d865a",
  "/mobile-aoc/weight-loss": "aa061a25-546a-45e8-b432-014a46edfc22",
  "/reviews": "08c0b111-3a9e-4cff-99e8-cc8373924a42",
  "/what-is-adhd/full-interview": "bcf7cd13-f627-4d0a-8310-277a89bc96f8",
  "/what-we-treat/adhd": "bcf7cd13-f627-4d0a-8310-277a89bc96f8",
  "/what-we-treat/adhd-nyc": "08c0b111-3a9e-4cff-99e8-cc8373924a42",
  "/what-we-treat/adhd-providers/everything":
    "bcf7cd13-f627-4d0a-8310-277a89bc96f8",
  "/what-we-treat/allergies": "c79c06ba-ecad-4376-b404-791f4f83e216",
  "/what-we-treat/anemia-care": "caf3a884-651c-4392-8630-1e64eb9bd4b0",
  "/what-we-treat/annual-checkup": "cab4781f-824c-4a88-8cae-a8241ff47d12",
  "/what-we-treat/anxiety": "5c2673a1-3b2f-4ee9-95b6-d876a1a8ed7e",
  "/what-we-treat/anxiety-depression": "5c2673a1-3b2f-4ee9-95b6-d876a1a8ed7e",
  "/what-we-treat/asthma": "d9030222-dad0-4081-b79c-ab1bde6150bd",
  "/what-we-treat/bronchitis": "92ddd2d2-7246-4273-bef5-e7951dddd4e0",
  "/what-we-treat/depression": "5c2673a1-3b2f-4ee9-95b6-d876a1a8ed7e",
  "/what-we-treat/diabetes-care-circle": "de491cb3-9d55-450f-a875-cb7afb21b285",
  "/what-we-treat/foresight": "de491cb3-9d55-450f-a875-cb7afb21b285",
  "/what-we-treat/hair-loss": "fc578f7e-7d56-4e6f-a938-dcd52d93ec5a",
  "/what-we-treat/heartburn": "de491cb3-9d55-450f-a875-cb7afb21b285",
  "/what-we-treat/hormone-therapy": "b25c192e-8e48-49e4-b8fc-1b0e8256ec79",
  "/what-we-treat/hypertension": "2555c692-aa3a-4b5c-a530-15b7ce6516f4",
  "/what-we-treat/hypothyroidism": "cd40c045-93bc-4831-9b98-fc00577512a7",
  "/what-we-treat/ibs": "c256b6a1-1a9d-431e-8e88-eeeeb747c8d1",
  "/what-we-treat/insomnia": "1e314f52-9473-4aef-9b4e-295df52d865a",
  "/what-we-treat/menopause-hormone-therapy":
    "04a55246-439b-4107-a79f-9e4703ac32d1",
  "/what-we-treat/mental-health": "5c2673a1-3b2f-4ee9-95b6-d876a1a8ed7e",
  "/what-we-treat/mental-health-at-circle":
    "5c2673a1-3b2f-4ee9-95b6-d876a1a8ed7e",
  "/what-we-treat/migraines": "a09d5945-2537-4a02-9ae3-1dba862adf45",
  "/what-we-treat/myrisk": "de491cb3-9d55-450f-a875-cb7afb21b285",
  "/what-we-treat/prep": "c75ddf4c-fa3f-4799-9e5b-83b5d6814702",
  "/what-we-treat/primary-care": "de491cb3-9d55-450f-a875-cb7afb21b285",
  "/what-we-treat/sinusitis": "a84a8bc7-41bc-4205-b830-c11e3d5ace6f",
  "/what-we-treat/skincare-circle": "624b99c6-869e-4225-b5ab-867580eb3504",
  "/what-we-treat/sleep-and-migraines": "1e314f52-9473-4aef-9b4e-295df52d865a",
  "/what-we-treat/sleep-apnea": "1e314f52-9473-4aef-9b4e-295df52d865a",
  "/what-we-treat/sleep-medicine": "1e314f52-9473-4aef-9b4e-295df52d865a",
  "/what-we-treat/sleep-study": "1e314f52-9473-4aef-9b4e-295df52d865a",
  "/what-we-treat/strep-throat-and-tonsillitis":
    "de491cb3-9d55-450f-a875-cb7afb21b285",
  "/what-we-treat/thyroid": "cd40c045-93bc-4831-9b98-fc00577512a7",
  "/what-we-treat/tobacco-cessation": "de491cb3-9d55-450f-a875-cb7afb21b285",
  "/what-we-treat/vaccinations": "de491cb3-9d55-450f-a875-cb7afb21b285",
  "/what-we-treat/weight-loss": "aa061a25-546a-45e8-b432-014a46edfc22",
  "/what-we-treat/wellness-360": "cab4781f-824c-4a88-8cae-a8241ff47d12",
  "/what-we-treat/womens-hormone-therapy":
    "04a55246-439b-4107-a79f-9e4703ac32d1",
}

export const bookProductLink = (productName) => {
  const url = new URL(bookingUrl())
  if (productIds[productName]) {
    url.searchParams.append("product_id", productIds[productName])
  }
  return url.toString()
}

export const bookingUrl = () => {
  return import.meta.env.PUBLIC_CIRCLE_MEDICAL_BOOKING_URL
}

export const isBookingLink = (url) => {
  return url.includes(bookingUrl())
}

export const checkoutUrl = (provider, timeslot, product, zipCode) => {
  return `https://booking-v2.circlemedical.com/checkout/?provider_id=${provider.id}&timeslot_id=${timeslot.id}/${timeslot.start_time}/30&product_id=${product}&zip_code=${zipCode}&utm_source=providers-near-me`
}
