import { Controller } from "@hotwired/stimulus"

import { bookProductFromCondition } from "@core/homepageBookingFlow"

import conditions from "@pages/_conditions"
import insurances from "@pages/_insurances"
import { OUT_OF_POCKET } from "@pages/_insurances"
import { track } from "@client/analytics"

export default class extends Controller {
  static targets = [
    "condition",
    "zipCode",
    "insurance",
    "conditionMobile",
    "insuranceMobile",
    "conditionsDropdown",
    "insurancesDropdown",
    "reason",
    "submit",
  ]

  connect() {
    document.addEventListener("click", this.handleDocumentClick.bind(this))
    this.addInputListeners()
    this.showEraseButton(this.zipCodeTarget)
  }

  handleDocumentClick(event) {
    const clickedElement = event.target.id
    const clickableElements = ["input", "icon", "background", "erase"]
    const isClickableElement = clickableElements.includes(clickedElement)
    if (isClickableElement) return
    this.deselectInputs()
  }

  addInputListeners() {
    const targets = [
      this.conditionTarget,
      this.zipCodeTarget,
      this.insuranceTarget,
      this.conditionMobileTarget,
      this.insuranceMobileTarget,
    ]

    targets.forEach((target) => {
      const input = target.querySelector("input")
      input.addEventListener("input", () => {
        this.updateURL()
        if (input.value.length === 0) this.hideEraseButton(target)
        else this.showEraseButton(target)
      })
    })

    this.submitTarget.addEventListener("click", () => {
      track("Condition Treatment Searched", {
        condition: this.conditionTarget.querySelector("input").value,
        zip_code: this.zipCodeTarget.querySelector("input").value,
        insurance: this.insuranceTarget.querySelector("input").value,
      })
    })
  }

  focusInput(event) {
    this.deselectInputs()
    const targetName = event.currentTarget.dataset.widgetTarget
    const target = this[`${targetName}Target`]
    const input = target.querySelector("input")

    input.select()
    if (targetName.includes("condition")) {
      this.showDropdown("conditions")
      this.syncInputs(input, this.conditionMobileTarget)
    }
    if (targetName.includes("insurance")) {
      this.showDropdown("insurances")
      this.syncInputs(input, this.insuranceMobileTarget)
    }
    target.querySelector("#icon").classList.add("!fill-[#1770F5]")
  }

  deselectInputs() {
    const targets = [
      this.conditionTarget,
      this.zipCodeTarget,
      this.insuranceTarget,
      this.conditionMobileTarget,
      this.insuranceMobileTarget,
    ]

    targets.forEach((target) => {
      target.querySelector("#icon").classList.remove("!fill-[#1770F5]")
    })
    this.conditionsDropdownTarget.classList.add("hidden")
    this.insurancesDropdownTarget.classList.add("hidden")

    this.updateURL()
  }

  syncInputs(mainInput, mobileTarget) {
    const mobileInput = mobileTarget.querySelector("input")
    const inputName = mobileTarget
      .closest("[data-widget-target]")
      .dataset.widgetTarget.replace("Mobile", "s")
    mobileInput.focus()
    this.addInputListener(mainInput, mobileInput, inputName)
    this.addInputListener(mobileInput, mainInput, inputName)
    mobileTarget.querySelector("#icon").classList.add("!fill-[#1770F5]")
  }

  addInputListener(firstInput, secondInput, inputName) {
    firstInput.addEventListener("input", () => {
      secondInput.value = firstInput.value
      this.showDropdown(inputName)
    })
  }

  showDropdown(name) {
    const dropdown =
      name === "conditions"
        ? this.conditionsDropdownTarget
        : this.insurancesDropdownTarget
    const target =
      name === "conditions" ? this.conditionTarget : this.insuranceTarget
    this.update(dropdown, target)
    dropdown.classList.remove("hidden")
  }

  update(dropdown, target) {
    const targetName = target.dataset.widgetTarget
    if (targetName.includes("Mobile")) return

    const display = dropdown.querySelector("#list")
    const mobileDisplay = dropdown.querySelector("#mobile-list")
    const input = target.querySelector("input")
    const value = input.value.trim()

    const methodDictionary = {
      condition: {
        getList: this.getConditions.bind(this),
        generateItem: this.generateConditionItem.bind(this),
      },
      insurance: {
        getList: this.getInsurances.bind(this),
        generateItem: this.generateInsuranceItem.bind(this),
      },
    }
    const methods = methodDictionary[targetName]

    const list = methods.getList(value)
    display.innerHTML = ""
    mobileDisplay.innerHTML = ""
    if (targetName === "insurance") {
      this.generateFirstInsuranceItem(target, display, list)
      this.generateFirstInsuranceItem(target, mobileDisplay, list)
    }
    if (targetName === "condition")
      value === "Other"
        ? this.reasonTarget.classList.remove("hidden")
        : this.reasonTarget.classList.add("hidden")

    list.forEach((item, index) => {
      const listItem = methods.generateItem(
        item,
        target,
        index === list.length - 1,
      )
      display.appendChild(listItem)
      const clonedListItem = listItem.cloneNode(true)
      const value =
        target.dataset.widgetTarget === "insurance" ? item.name : item
      clonedListItem.addEventListener("click", () =>
        this.selectOption(target, value),
      )
      mobileDisplay.appendChild(clonedListItem)
    })
  }

  getConditions(value) {
    const terms = value.toLowerCase().split(" ")
    const filtered = conditions.filter((c) =>
      this.filterElements(c, terms),
    )
    return filtered
  }

  getInsurances(value) {
    const terms = value.toLowerCase().split(" ")
    const filtered = insurances.filter(({ name }) =>
      this.filterElements(name, terms),
    )
    return value.length === 0 ? insurances : filtered
  }

  filterElements(element, terms) {
    return element
      .toLowerCase()
      .split(" ")
      .some((substring) => terms.some((term) => substring.startsWith(term)))
  }

  generateConditionItem(item, target, isLast) {
    const input = target.querySelector("input")
    const listItem = document.createElement("li")
    listItem.className =
      "w-full py-4 pl-1 cursor-pointer hover:bg-neutral-10-background"
    if (!isLast) listItem.classList.add("border-b")

    const value = input.value.trim().toLowerCase()
    const terms = value.split(" ")
    const regex = new RegExp(`(${terms.join("|")})`, "gi")
    const highlightedText = item.replace(
      regex,
      (match) => `<span class="text-[#1770F5]">${match}</span>`,
    )

    listItem.innerHTML = highlightedText
    listItem.addEventListener("click", () => this.selectOption(target, item))
    return listItem
  }

  generateInsuranceItem(item, target, isLast) {
    const listItem = document.createElement("li")
    listItem.className =
      "w-full py-4 pl-1 cursor-pointer hover:bg-neutral-10-background"
    if (!isLast) listItem.classList.add("border-b")

    listItem.innerHTML = `<Image src="${item.image.src}" alt="${item.name}" class="max-h-8" />`

    listItem.addEventListener("click", () =>
      this.selectOption(target, item.name),
    )
    return listItem
  }

  generateFirstInsuranceItem(target, display, list) {
    const firstListItem = document.createElement("li")
    firstListItem.className =
      "w-full py-4 pl-1 border-b cursor-pointer flex gap-2 items-center hover:bg-neutral-10-background"
    firstListItem.innerHTML = `<img src="${OUT_OF_POCKET.image.src}" alt="${OUT_OF_POCKET.name}" class="max-h-10" /> I'm paying out of pocket`
    firstListItem.addEventListener("click", () =>
      this.selectOption(target, "I'm paying out of pocket"),
    )
    display.appendChild(firstListItem)

    if (list.length !== 0) {
      const infoText = document.createElement("li")
      infoText.className =
        "flex my-4 pl-1 items-center w-full h-8 text-neutral-50-secondary-text"
      infoText.textContent = "Or select insurance company"
      display.appendChild(infoText)
    }
  }

  selectOption(target, value) {
    const input = target.querySelector("input")
    input.value = value
    if (target.dataset.widgetTarget === "condition")
      this.conditionMobileTarget.querySelector("input").value = value
    else this.insuranceMobileTarget.querySelector("input").value = value
    this.deselectInputs()
    this.showEraseButton(target)
  }

  updateURL() {
    const condition = this.conditionTarget.querySelector("input").value
    const zipCode = this.zipCodeTarget.querySelector("input").value
    const insurance = this.insuranceTarget.querySelector("input").value
    const reason =
      condition === "Other"
        ? this.reasonTarget.querySelector("input").value
        : this.conditionTarget.querySelector("input").value
    const bookingUrl = bookProductFromCondition({
      condition,
      zipCode,
      insurance,
      reason,
    })
    this.submitTarget.href = bookingUrl
  }

  eraseValue(event) {
    const target = event.currentTarget.closest("[data-widget-target]")
    this.eraseValueFromInputs(target)
    this.hideEraseButton(target)
  }

  eraseValueFromInputs(target) {
    const input = target.querySelector("input")
    input.value = ""
    const targetName = target.dataset.widgetTarget
    if (targetName.includes("Mobile")) {
      const mainTarget =
        this[targetName.replace("Mobile", "") + "Target"]
      mainTarget.querySelector("input").value = ""
    } else if (targetName === "condition" || targetName === "insurance") {
      const mobileTarget = this[targetName + "MobileTarget"]
      mobileTarget.querySelector("input").value = ""
    }
  }

  showEraseButton(target) {
    target.querySelector("#erase").classList.remove("hidden")
    const targetName = target.dataset.widgetTarget
    if (targetName.includes("Mobile")) {
      const mainTarget =
        this[targetName.replace("Mobile", "") + "Target"]
      mainTarget.querySelector("#erase").classList.remove("hidden")
    } else if (targetName === "condition" || targetName === "insurance") {
      const mobileTarget = this[targetName + "MobileTarget"]
      mobileTarget.querySelector("#erase").classList.remove("hidden")
    }
  }

  hideEraseButton(target) {
    target.querySelector("#erase").classList.add("hidden")
    const targetName = target.dataset.widgetTarget
    if (targetName.includes("Mobile")) {
      const mainTarget =
        this[targetName.replace("Mobile", "") + "Target"]
      mainTarget.querySelector("#erase").classList.add("hidden")
    } else if (targetName === "condition" || targetName === "insurance") {
      const mobileTarget = this[targetName + "MobileTarget"]
      mobileTarget.querySelector("#erase").classList.add("hidden")
    }
  }
}
