import { bookingUrl } from "./book"

export const bookProductFromCondition = ({
  condition,
  zipCode,
  insurance,
  reason,
}) => {
  let url = `${bookingUrl()}/availabilities/`
  const params = new URLSearchParams()

  const product = conditions[condition]
  const productId = productIds[product] || null

  const insuranceId = insurances[insurance] || null

  if (productId) params.append("product_id", productId)
  if (zipCode) params.append("zip_code", zipCode)
  if (insuranceId) params.append("insurance_id", insuranceId)
  if (reason) params.append("reason", reason)

  if (params.toString().length) url += `?${params.toString()}`
  return url
}

const isProduction = () =>
  import.meta.env.PUBLIC_RUNNING_ENV === "production"

const prodInsurances = {
  Aetna: "5c5feb8d-ceae-4abd-8e42-364da2e19c2e",
  Anthem: "1397c916-1454-45b9-bd20-5fd27eca8c6d",
  "Cigna Insurance": "a0f9af07-4ded-4118-b44f-677d3841b309",
  "Health Net": "78c4ae61-a11d-4188-a727-30083d7db642",
  "United Healthcare": "0b463414-b7a2-4dc4-85f4-f578cbea32bd",
  UMR: "0e2e58e3-57b8-4e26-b72c-ec007e5574e9",
  "Blue Cross/Blue Shield": "dca5b57e-2269-4286-a1a9-4ef1f7d288b8",
  "Meritain Health": "6f3a9359-889d-4239-bc3c-dc0a0ab8bbc5",
}

const devInsurances = {
  "Blue Shield of California": "212c4ce2-f4cf-4b01-815f-8551874a93f6",
  "Health Net": "5feb1e22-8eee-4234-99c2-f7a0d0b6910b",
  "Cigna Insurance": "a3c1e436-0761-4ddb-883f-f9ad5b35b706",
  "Kaiser Permanente": "8deccee5-e444-4bc8-9880-dae8bf36e612",
  Anthem: "53f2c167-ae6a-44fe-80df-5b1f95abee4a",
  "United Healthcare": "7617e751-3a5f-441c-9286-765a44f3f8c4",
  Medicare: "ddcbe031-019a-4229-999d-5e9cca960724",
  "Premera Blue Cross": "84f7451e-1406-4385-ad41-2f1ece3d1adf",
  "Medi-Cal": "96d0f870-afad-4a81-84be-89ef827aadb7",
  Medicaid: "6fe90775-9613-4e9f-9400-4e697b884f2c",
  Aetna: "4d89a405-5196-4d39-ab6e-4fc84d32e1a9",
  Humana: "2e9a6d8f-7d1a-4187-924c-95149ac0374a",
  "Empire Blue Cross Blue Shield": "2211e7dd-7464-4f51-9d46-99a0a0e4d1dc",
  UMR: "49ed79f0-b2a6-4502-b674-6f507274ea86",
  "CareFirst Blue Cross Blue Shield  (Health)":
    "62ff323f-351e-4bf4-a578-5d15e2faa9d6",
  "Florida Blue: Blue Cross Blue Shield of Florida":
    "5f80dd91-bc7a-49b5-8db8-56119aae7170",
}

const conditions = {
  "Abdominal Discomfort": "Video Appointment",
  Abscess: "Skin Care Video Appointment",
  "Acid Reflux": "Video Appointment",
  Acne: "Skin Care Video Appointment",
  "Acute Cough": "Video Appointment",
  Allergies: "Allergies Video Appointment",
  "Allergic Asthma": "Asthma Video Appointment",
  "Allergic Cough": "Allergies Video Appointment",
  "Allergic Eye Problems": "Allergies Video Appointment",
  "Androgenetic Alopecia": "Hair Loss Video Appointment",
  Anemia: "Anemia Video Appointment",
  "Annual Check Up": "Video Wellness Exam",
  "Annual Physical": "Video Wellness Exam",
  Anxiety: "Anxiety and Depression Video Assessment",
  "Anxiety Disorder": "Anxiety and Depression Video Assessment",
  Arthritis: "Video Appointment",
  Asthma: "Asthma Video Appointment",
  Atherosclerosis: "Video Appointment",
  "Athlete's Foot": "Video Appointment",
  "Bad breath / Halitosis": "Video Appointment",
  "Birth Control / Contraception": "Video Appointment",
  Blisters: "Skin Care Video Appointment",
  Bloating: "Video Appointment",
  "Blood Pressure Testing": "Hypertension Video Appointment",
  "Blood Work": "Video Appointment",
  "Bone Density Measurement / DXA Scan": "Video Appointment",
  "Breast Cancer Screening": "Video Appointment",
  Bronchitis: "Bronchitis Video Appointment",
  "Burning Sensation in Urine": "Video Appointment",
  "Calcium CT Scan": "Video Appointment",
  "Cancer Screening": "Video Appointment",
  "Cholesterol / Lipids Checkup": "Video Appointment",
  "Cholesterol Management": "Video Appointment",
  "Chronic Cough": "Video Appointment",
  "Chronic Fatigue Syndrome": "Video Appointment",
  "Chronic Illness": "Video Appointment",
  "Chronic Migraine": "Migraine Video Appointment",
  "Chronic Obstructive Pulmonary Disease (COPD)": "Video Appointment",
  "Cold Sores / Herpes Labialis": "Video Appointment",
  "Colon Cancer Screening": "Video Appointment",
  "Colorectal Cancer Screening": "Video Appointment",
  "Confirmation of Pregnancy": "Video Appointment",
  Conjunctivitis: "Video Appointment",
  Constipation: "Video Appointment",
  "Continuous Positive Airway Pressure (CPAP)": "Sleep Video Appointment",
  Contraception: "Video Appointment",
  Cough: "Video Appointment",
  "COVID-19 Testing": "Video Appointment",
  Dandruff: "Video Appointment",
  "Daytime Sleepiness": "Sleep Video Appointment",
  Depression: "Anxiety and Depression Video Assessment",
  "Diabetes Blood Sugar Management": "Diabetes Video Appointment",
  Diabetes: "Diabetes Video Appointment",
  Diarrhea: "Video Appointment",
  "Difficulty Urinating": "Video Appointment",
  "Digestive Problem": "Video Appointment",
  Dysphagia: "Video Appointment",
  "Ear Drainage / Discharge": "Video Appointment",
  "Ear Fullness / Popping": "Video Appointment",
  "Ear Infection": "Video Appointment",
  "Ear Inflammation": "Video Appointment",
  "Ear Pain": "Video Appointment",
  Eczema: "Skin Care Video Appointment",
  Emphysema: "Video Appointment",
  "Erectile Dysfunction / Impotence / Male Sexual Dysfunction":
    "Video Appointment",
  "Eye Infection": "Video Appointment",
  "Eye Redness": "Video Appointment",
  "Fatty Liver Disease": "Video Appointment",
  "Fertility Awareness / Natural Family Planning": "Video Appointment",
  Fever: "Video Appointment",
  Fibromyalgia: "Video Appointment",
  Flu: "Video Appointment",
  "Flu Shot": "Video Appointment",
  "Frequent Urination": "Video Appointment",
  "Fungal Infection": "Video Appointment",
  "Gastroesophageal Reflux Disease (GERD)": "Video Appointment",
  "Gastrointestinal Problem": "Video Appointment",
  "Gender Affirming Counseling": "Video GAHT Intake Appointment",
  "General Consultation": "Video Appointment",
  "General Follow Up": "Video Appointment",
  "Genital Herpes": "Video Appointment",
  Gout: "Video Appointment",
  "Hair Loss": "Hair Loss Video Appointment",
  "Hay Fever / Seasonal Allergies": "Allergies Video Appointment",
  "Head Lice": "Video Appointment",
  Headache: "Video Appointment",
  Heartburn: "Video Appointment",
  "Helicobacter Pylori (H. Pylori) Infection": "Video Appointment",
  "Herpes Simplex": "Video Appointment",
  "High Blood Pressure / Hypertension": "Hypertension Video Appointment",
  "High Blood Sugar / Hyperglycemia": "Diabetes Video Appointment",
  "High Cholesterol / Lipid Problem": "Video Appointment",
  "High Triglyceride Levels": "Video Appointment",
  Hirsutism: "Video Appointment",
  "HIV Pre-Exposure Prophylaxis (PrEP)": "Video PrEP Intake Appointment",
  Hives: "Video Appointment",
  "Hospital Discharge / Follow Up": "Video Appointment",
  "Hot Flashes": "Menopause Video Appointment",
  "Human Papillomavirus (HPV) Vaccine": "Video Appointment",
  "Hyperactive Disorder (ADD / ADHD)": "Video ADHD Intake Appointment",
  Hypersomnia: "Video Appointment",
  "Hyperthyroidism / Overactive Thyroid": "Thyroid Video Appointment",
  "Hypothyroidism / Underactive Thyroid": "Thyroid Video Appointment",
  Illness: "Video Appointment",
  Immunization: "Video Appointment",
  "Insect Bite(s)": "Video Appointment",
  Insomnia: "Sleep Video Appointment",
  "Irregular Menstruation": "Video Appointment",
  "Irritable Bowel Syndrome (IBS)":
    "Irritable Bowel Syndrome (IBS) Video Appointment",
  Itching: "Video Appointment",
  "Jock Itch": "Video Appointment",
  "Lactose Intolerance": "Video Appointment",
  "LGBT Care": "Video GAHT Intake Appointment",
  "Liver Function Test": "Video Appointment",
  "Low Libido": "Video Appointment",
  "Low Testosterone": "Video Appointment",
  "Lung Cancer Screening": "Video Appointment",
  "Medical Weight Management": "Weight Management Video Appointment",
  "Medication Management": "Video Appointment",
  "Medication Review": "Video Appointment",
  "Men's Mental Health Issues": "Anxiety and Depression Video Assessment",
  "Menstrual Cramp(s)": "Video Appointment",
  "Menstrual Problems": "Video Appointment",
  "Mental Health Consultation": "Anxiety and Depression Video Assessment",
  "Mental Health Problem": "Anxiety and Depression Video Assessment",
  Migraine: "Migraine Video Appointment",
  "Missed Periods": "Video Appointment",
  "Molluscum Contagiosum": "Video Appointment",
  "Mouth Ulcer": "Video Appointment",
  "Nasal Allergies": "Video Appointment",
  "Nasal Congestion": "Video Appointment",
  "Nausea and Vomiting": "Video Appointment",
  "New Patient Visit": "Video Appointment",
  "Obesity / Weight Loss Consultation": "Weight Management Video Appointment",
  "Obesity Nutrition Consultation": "Weight Management Video Appointment",
  "Obstructive Sleep Apnea (OSA)": "Sleep Video Appointment",
  "Over Sleeping": "Sleep Video Appointment",
  "Painful Urination / Dysuria": "Video Appointment",
  "Panic Attack": "Anxiety and Depression Video Assessment",
  "Panic Disorder": "Anxiety and Depression Video Assessment",
  "Paronychia (Nail Infection)": "Video Appointment",
  "Patient History": "Video Appointment",
  Periods: "Video Appointment",
  Pharyngitis: "Strep Throat Video Appointment",
  "Strep Throat": "Strep Throat Video Appointment",
  "Pneumococcal Vaccine": "Video Appointment",
  "Poison Ivy or Poison Oak": "Skin Care Video Appointment",
  "Polycystic Ovary Syndrome / Metabolic Syndrome": "Video Appointment",
  "Post-Nasal Drip": "Video Appointment",
  "Postpartum Anxiety": "Anxiety and Depression Video Assessment",
  "Postpartum Depression": "Anxiety and Depression Video Assessment",
  "Pre-Travel Checkup": "Video Appointment",
  Prediabetes: "Diabetes Video Appointment",
  "Premenstrual Syndrome (PMS) / Premenstrual Dysphoric Disorder (PMDD)":
    "Video Appointment",
  "Prescription / Refill": "Video Appointment",
  "Primary Care Consultation": "Video Appointment",
  "Prostate Cancer Screening": "Video Appointment",
  Psoriasis: "Skin Care Video Appointment",
  "Quitting Smoking / Smoking Cessation": "Video Appointment",
  "Recurrent Urinary Tract Infections": "Video Appointment",
  Rhinitis: "Video Appointment",
  Ringworm: "Video Appointment",
  "Screening for Disease / Preventive Medicine": "Video Appointment",
  "Screening Mammogram (No Breast Cancer Symptoms)": "Video Appointment",
  Shingles: "Video Appointment",
  "Sinus Headache": "Video Appointment",
  "Sinus Problems / Sinusitis": "Video Appointment",
  "Sleep Apnea / Snoring Device": "Sleep Video Appointment",
  "Sleep Disorder": "Sleep Video Appointment",
  "Sleep Problems": "Sleep Video Appointment",
  "Sleep Studies": "Sleep Video Appointment",
  Snoring: "Sleep Video Appointment",
  "Social Anxiety": "Anxiety and Depression Video Assessment",
  "Sore Throat": "Video Appointment",
  "Stomach Discomfort": "Video Appointment",
  Stress: "Anxiety and Depression Video Assessment",
  "Swallowing Problems": "Video Appointment",
  "Throat Pain": "Video Appointment",
  Thyroid: "Thyroid Video Appointment",
  "Thyroid Function Tests": "Thyroid Video Appointment",
  "Tinea Versicolor": "Video Appointment",
  "Toenail Fungus": "Video Appointment",
  "Toenail Problem": "Video Appointment",
  "Transgender Care": "Video GAHT Intake Appointment",
  "Transgender Hormone Therapy": "Video GAHT Intake Appointment",
  "Type 1 Diabetes": "Diabetes Video Appointment",
  "Type 2 Diabetes": "Diabetes Video Appointment",
  "Upper Respiratory Infection": "Video Appointment",
  "Urinary Tract Infection (UTI)": "Video Appointment",
  "Urology Problem": "Video Appointment",
  "Vaccine(s)": "Video Appointment",
  "Vitamin B12 Deficiency": "Video Appointment",
  "Wart(s)": "Video Appointment",
  "Weight Loss Consultation": "Weight Management Video Appointment",
  "Weight Loss Follow Up": "Weight Management Video Appointment",
  "Weight Management": "Weight Management Video Appointment",
  "Wellness Care": "Annual Wellness Exam",
  Wheezing: "Video Appointment",
  "Yeast Infection": "Video Appointment",
  "Video Wellness Exam": "Video Wellness Exam",
  PrEP: "Video PrEP Intake Appointment",
  GAHT: "Video GAHT Intake Appointment",
  "Skin Care": "Skin Care Video Appointment",
  ADHD: "Video ADHD Intake Appointment",
  "Prescription Refill": "Video Appointment",
  "Cough/Sore Throat": "Video Appointment",
  Hypertension: "Hypertension Video Appointment",
  UTI: "Video Appointment",
  Other: "Video Appointment",
}

const prodProductIds = {
  "Video Appointment": "de491cb3-9d55-450f-a875-cb7afb21b285",
  "Appointment Order": "8b9ee2f8-66c6-4672-8fe5-490cc0e2d205",
  "Annual Wellness Exam": "d8023255-5024-4256-a76f-c10c1d0c15a3",
  "Lab Tech Appointment": "32dc3727-8981-416b-9b2b-e8bc0098779c",
  "COVID-19 Test - Blood Draw": "d7f0cc03-6baf-4538-a5ec-691d6b7566d1",
  "COVID-19 Test - Swab": "e00b0d14-d699-4e28-a042-922d33c40b83",
  "Video Appointment Order": "66311600-c9d4-4ffa-ae8b-fa8f72f6f9fa",
  "Video Wellness Exam": "cab4781f-824c-4a88-8cae-a8241ff47d12",
  "Anxiety and Depression Video Assessment":
    "5c2673a1-3b2f-4ee9-95b6-d876a1a8ed7e",
  "Annual GYN Exam": "23be04b9-bd2c-4215-a36b-edfab77665e5",
  "Nexplanon In-Person Appointment": "1ca1ccd2-d18d-417c-b2ac-91b89a399b2b",
  "Video PrEP Intake Appointment": "c75ddf4c-fa3f-4799-9e5b-83b5d6814702",
  "Flu Vaccination": "e55e98ae-9ef4-4303-8408-029368e8c57b",
  "Sleep Video Appointment": "1e314f52-9473-4aef-9b4e-295df52d865a",
  "COVID-19 Vaccination": "68725485-a283-4e5e-9aaa-9544ffcb2a21",
  "Standard Appointment": "6355897b-8aac-49dc-9d14-95ab008a46bb",
  "Sinusitis Video Appointment": "a84a8bc7-41bc-4205-b830-c11e3d5ace6f",
  "Allergies Video Appointment": "c79c06ba-ecad-4376-b404-791f4f83e216",
  "Asthma Video Appointment": "d9030222-dad0-4081-b79c-ab1bde6150bd",
  "Bronchitis Video Appointment": "92ddd2d2-7246-4273-bef5-e7951dddd4e0",
  "Hair Loss Video Appointment": "fc578f7e-7d56-4e6f-a938-dcd52d93ec5a",
  "In-person ADHD Appointment": "4a0a6452-023a-4b31-a8f1-52327e8696c5",
  "Thyroid Video Appointment": "cd40c045-93bc-4831-9b98-fc00577512a7",
  "Anemia Video Appointment": "caf3a884-651c-4392-8630-1e64eb9bd4b0",
  "Hypertension Video Appointment": "2555c692-aa3a-4b5c-a530-15b7ce6516f4",
  "Migraine Video Appointment": "a09d5945-2537-4a02-9ae3-1dba862adf45",
  "Irritable Bowel Syndrome (IBS) Video Appointment":
    "c256b6a1-1a9d-431e-8e88-eeeeb747c8d1",
  "Diabetes Video Appointment": "1c738dd8-4eaa-48c3-bde3-5d3f1db2bbdd",
  "Strep Throat Video Appointment": "be58b4d5-1135-47fd-8675-a26d2056ebe8",
  "Video GAHT Intake Appointment": "b25c192e-8e48-49e4-b8fc-1b0e8256ec79",
  "Weight Management Video Appointment": "aa061a25-546a-45e8-b432-014a46edfc22",
  "Skin Care Video Appointment": "624b99c6-869e-4225-b5ab-867580eb3504",
  "Video ADHD Appointment with in-person follow-up":
    "08c0b111-3a9e-4cff-99e8-cc8373924a42",
  "Video ADHD Intake Appointment": "bcf7cd13-f627-4d0a-8310-277a89bc96f8",
}

const devProductIds = {
  "In-person ADHD Appointment": "92738ab7-05ef-4008-a0cd-e9df918f67de",
  "Flu Vaccination": "366f410d-8d29-4829-85ab-cd083035d6ac",
  "COVID-19 Vaccination": "30e39572-5673-424d-9e80-cae84dbbd93c",
  "Video Appointment Order": "ba6eea1b-313e-423e-9a90-8f98603348de",
  "Video Appointment": "73830ff7-91ce-46ed-9997-411f3caed560",
  "Appointment Order": "58ccb14a-192a-4c66-b3ab-45a4b072979d",
  "COVID-19 Test - Video Assessment": "f92a90dc-7a59-4d9a-97b6-9db4ee0a3589",
  "Video Wellness Exam": "521f001d-b5e6-4265-a88c-cf563cdf30d2",
  "Annual Wellness Exam": "7e124cce-0f24-4d79-831a-a62bf3f53e33",
  "COVID-19 Test - Swab": "b6449836-add7-4716-b7ab-6d1cfe2d8b50",
  "COVID-19 Test - Blood Draw": "eb1fa5e2-58f8-4020-bed8-7b07584ebcb4",
  "Standard Appointment": "81413ff9-e1d0-4483-8c21-bf65fba6c849",
  "Lab Tech Appointment": "5c1d605c-3ea0-4208-a28e-4f1e5e5b7022",
  "Video PrEP Intake Appointment": "647e67aa-1961-43be-8a17-ea84d8ec4558",
  "Thyroid Video Appointment": "8d268385-9caf-40d9-9e78-9fd5f82bc746",
  "Bronchitis Video Appointment": "7df55922-b670-47e0-a395-23df256d3087",
  "Allergies Video Appointment": "bfacb4be-8406-4e3f-bd50-6d1db7452ee8",
  "Sleep Video Appointment": "53ea181f-4e71-4b9d-9a38-30137b789ea6",
  "Sinusitis Video Appointment": "d0c52f04-1d63-42da-aa4b-d3a193f93295",
  "Brief Appointment": "94382643-3f9b-4a50-a752-e111715bd2b1",
  "Anxiety and Depression Video Assessment":
    "acb9b358-10d0-4ff2-a640-f7ebca22b4bb",
  "Asthma Video Appointment": "109b2604-9fe9-44cf-a7ca-8bead100c214",
  "Video GAHT Intake Appointment": "2404d090-8465-46bb-b837-4b7f1265d29d",
  "Hair Loss Video Appointment": "f477acb6-d077-4fe2-8786-c3013f5293b5",
  "Irritable Bowel Syndrome (IBS) Video Appointment":
    "a2615316-8d3e-4813-91f7-8b944cbfddeb",
  "Diabetes Video Appointment": "cacadf85-9c32-4535-af4b-1fd408bfd4d6",
  "Strep Throat Video Appointment": "7ff307e8-0527-460b-8987-70f2bfb31662",
  "Nexplanon In-Person Appointment": "8b404573-c3ca-4975-bb13-4c6de61133c9",
  "Skin Care Video Appointment": "e32fc486-031c-4471-8cda-e8299ed2fe09",
  "Weight Management Video Appointment": "5c9e0b4e-e59d-494d-aca7-540a52b61d80",
  "Video ADHD Intake Appointment": "b2e85630-333c-47c1-b130-d7a6b8dc98c7",
  "Annual GYN Exam": "40ca0ffc-6d35-46b2-918c-b50508280695",
}

const insurances = isProduction() ? prodInsurances : devInsurances
const productIds = isProduction() ? prodProductIds : devProductIds
